import React from "react"
import { graphql, Link } from "gatsby"

import { ClientOnly, Layout } from "../components/common"
import { MetaData } from "../components/common/meta"
import drawRacingLine from "../images/draw-racing-line.jpg"
import List from "../components/idealline/List"
import FirebaseProvider from "../components/idealline/Firebase"
import { BrowserRouter } from "react-router-dom"
import Gokart from "../components/tracks/Gokart"

const RacingLines = ({ location }) => {
    const title = "Racing Lines - Perfect Cornering Techniques"

    return (
        <>
            <MetaData
                location={location}
                title={title}
                description="Draw your racing line and perfect your cornering techniques."
                image={drawRacingLine}
            />
            <Layout title={title} image={drawRacingLine}>
                <section className="post-full-content">
                    <p>
                        Mastering the racing line is one of the first tasks I am
                        doing to work on my lap times. Before I go to a
                        racetrack, I will draw my line onto the map and
                        prioritize the corners which I would like to work on
                        first.
                    </p>
                    <ClientOnly>
                        <BrowserRouter>
                            <FirebaseProvider>
                                <h1>Latest Racing Lines</h1>
                                <List
                                    wrapperClass="post-feed horizontal-feed lines"
                                    limit={10}
                                />
                            </FirebaseProvider>
                        </BrowserRouter>
                    </ClientOnly>
                    <h1>Draw your racing line</h1>
                    <p>
                        Choose one of the following outdoor tracks and draw your
                        racing line for your next race.
                    </p>
                    <Gokart hideRacingLines />
                    <h1>How to draw your racing line</h1>

                    <p>
                        I am focusing on the fastest corners first and then
                        looking at the corners leading to a straightaway.
                    </p>

                    <ol>
                        <li>
                            The geometrical line is usually the best for fast
                            corners connecting straightaways
                        </li>
                        <li>
                            Late turn in and late apex for corners leading to a
                            straightaway
                        </li>
                        <li>
                            In a set of curves focus on the last corner leading
                            to a straightaway. My goal is to be as early as
                            possible on the throttle, apex late and maximize my
                            corner exit speed
                        </li>
                    </ol>

                    <p>
                        I think that satellite images of outdoor tracks are a
                        great way to analyse the track and draw racing lines. 🏁
                    </p>
                </section>
            </Layout>
        </>
    )
}
export default RacingLines
